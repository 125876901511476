
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-skeleton-wrapper :loading="loading">
              <!-- skeleton  -->
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>

              <div class="row justify-content-md-center mt-4">
                <div class="col-sm-12 col-md-12">
                  <div class="row align-items-center">
                    <div class="col-sm-12 col-md-3"></div>
                    <div class="col-sm-5 col-md-2">
                      <label class="d-inline align-items-center m-2 text-end">
                        รหัสอะไหล่:
                        <b-form-input
                          v-model="filter.partCode"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control"
                          @keyup.enter="handleSearch"
                        ></b-form-input>
                      </label>
                    </div>
                    <div class="col-sm-5 col-md-3">
                      <label class="d-inline align-items-center m-2">
                        ชื่ออะไหล่:
                        <b-form-input
                          v-model="filter.nameTh"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control"
                          @keyup.enter="handleSearch"
                        ></b-form-input>
                      </label>
                    </div>

                    <div class="col-sm-3 col-md-4">
                      <btnComponent @click="handleSearch" changeStyle="search">
                      </btnComponent
                      >&nbsp;&nbsp;&nbsp;&nbsp;
                      <router-link
                        :to="{ name: 'add-masterPart' }"
                        class="btn btn-primary"
                      >
                        <i class="mdi mdi-plus me-1"></i>เพิ่มข้อมูลอะไหล่
                      </router-link>
                    </div>
                    <!-- <div class="col-sm-3 col-md-2">
                      <router-link
                        :to="{ name: 'add-masterPart' }"
                        class="btn btn-primary float-end"
                      >
                        <i class="mdi mdi-plus me-1"></i>เพิ่มข้อมูลอะไหล่
                      </router-link>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row align-items-center">
                    <div class="col-sm-12 col-md-12">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          แสดงผล&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            @input="handlePageChange"
                          ></b-form-select
                          >&nbsp;รายการ
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Table -->
              <div class="table-responsive mb-0">
                <tableData :fields="fields" :items="rowParts">
                  <template #cell(sellPrice)="rowParts">
                    {{ Number(rowParts.item.sellPrice).toLocaleString() }}
                  </template>
                  <template #cell(index)="rowParts" v-if="this.currentPage > 1">
                    {{ rowParts.index + 1 + (currentPage * perPage - perPage) }}
                  </template>
                  <template #cell(index)="rowParts" v-else>
                    {{ rowParts.index + 1 }}
                  </template>
                  <!-- edit table -->
                  <template v-slot:cell()="{ value, item, field: { key } }">
                    <template v-if="!item.isEditing">{{ value }}</template>
                    <b-form-input v-else v-model="item[key]" />
                  </template>
                  <!-- end edit table -->
                  <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                      <span aria-hidden="true">&check;</span>
                      <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                      <span aria-hidden="true">&nbsp;</span>
                      <span class="sr-only">Not selected</span>
                    </template>
                  </template>

                  <!-- edit table -->
                  <template v-slot:cell(action)="rowParts">
                    <router-link
                      class="px-2 text-primary"
                      v-b-tooltip.hover
                      title="Edit"
                      :to="{
                        name: 'update-masterPart',
                        params: { partId: rowParts.item.partId },
                      }"
                    >
                      <i class="uil uil-pen font-size-18"></i>
                    </router-link>

                    <a
                      class="px-2 text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                      @click="alert(rowParts.item.partId)"
                    >
                      <i class="uil uil-trash-alt font-size-18"></i>
                    </a>
                  </template>
                  <!-- End edit table -->
                </tableData>
                <!-- <b-button v-model="selected" size="sm" @click="selectAllRows"
                  >Select all</b-button
                >
                &nbsp;

                <b-button size="sm" @click="clearSelected"
                  >Clear selected</b-button
                >

                <p>
                  Selected Rows:<br />
                  {{ selected }}
                </p> -->
              </div>
              <br />
              <div class="row">
                <span
                  v-if="this.totalRecord === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
                <div class="col" v-if="this.totalRecord > 0">
                  <div class="col">
                    <label class="d-inline-flex align-items-center">
                      หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                      {{ totalRecord }} รายการ
                    </label>
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-end
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRecord"
                          :per-page="perPage"
                          @change="handleChangePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import btnComponent from "@/components/btnComponent.vue";
import tableData from "@/components/tablecomponent";

export default {
  page: {
    title: "ข้อมูลอะไหล่",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, btnComponent, tableData },

  data() {
    return {
      loading: undefined,

      title: "ข้อมูลอะไหล่",
      items: [
        {
          text: "หน้าหลัก",
          href: "/",
        },
        {
          text: "ข้อมูลอะไหล่",
          active: true,
        },
      ],
      partId: this.$route.params.partId,
      filter: {
        partCode: "",
        nameTh: "",
      },
      totalPage: "",
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      to: "",
      total: "",
      from: "",
      totalRecord: "",
      rowParts: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filterOn: [],
      sortBy: "partId",
      sortDesc: false,
      selectedUsers: [],
      isSelectedAll: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
        },
        {
          key: "partCode",
          label: "รหัสอะไหล่",
          sortable: true,
        },
        {
          key: "year",
          label: "ปี(ค.ศ.)",
          sortable: true,
        },
        {
          key: "nameTh",
          label: "ชื่ออะไหล่",
          sortable: true,
        },
        {
          key: "sellPrice",
          label: "ราคา",
          sortable: true,
        },
        {
          key: "maxDcPer",
          label: "ส่วนลด(%)",
          sortable: true,
        },
        {
          key: "groupNameTh",
          label: "กลุ่มอะไหล่",
        },

        {
          key: "action",
          label: "",
        },
      ],
    };
  },

  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowParts.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.rowParts = this.items.length;
  },

  created() {
    this.getPartsM();
    this.startIndex = 1;
    this.endIndex = this.perPage;
    this.paginatedData = this.rowParts.slice(this.startIndex, this.endIndex);
  },
  methods: {
    handleSearch() {
      this.getPartsM();
    },
    onFiltered(filteredItems) {
      this.rowParts = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowParts) {
      this.selected = rowParts;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    getPartsM: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/central/parts", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            partCode: this.filter.partCode,
            nameTh: this.filter.nameTh,
          },
        })
        .then((response) => {
          this.rowParts = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
          // console.log(response);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    deleteParts: function (rowParts) {
      this.partData = rowParts;
      // console.log(this.partData);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/central/part/delete", {
          data: {
            partId: this.partData,
          },
        })
        .then(() => {
          // alert("ต้องการลบหรือไม่")
          this.getPartsM();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowParts) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteParts(rowParts);
            swalWithBootstrapButtons.fire(
              "Deleted!",
              "Your file has been deleted.",
              "success"
            );
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your imaginary file is safe :)",
              "error"
            );
          }
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getPartsM();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getParts();
    },
  },
  middleware: "authentication",
};
</script>
